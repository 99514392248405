import React from "react";
import "./bodyHome.scss";

export function BodyHome() {
  return (
    <section className="wrapper_section" id="top">
      <div className="topText">
      <div className="bolderText secondaryColor">
        Nuestra especialidad es tu <br />
      </div>
      <div className="bolderText thirdColor">
        <strong>bienestar y tu salud</strong>
      </div>
      </div>
      <br />
      {/* </section> */}
      <section className="lighterText secondaryColor text-2xl">
        <p className="centeredText">
          En <span className="boldText">Hospitales Cruz Azul</span> estamos
          comprometidos con tu salud, ofrecemos la mejor atención médica y los tratamientos de la más alta
          calidad.
        </p>
      </section>
    </section>
  );
}
