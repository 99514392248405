import React, { useState, useRef, useEffect } from "react";
import "./burguerMenuDesktop.scss";
import logo from "../../assets/imgs/Logo.png";

const BurguerMenuDesktop = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const menuRef = useRef();
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [open]);

  const handleOutsideClicks = (event) => {
    if (open && menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
      console.log("open: " + menuRef.current);
    }
  };
  return (
    <nav className="sticky-section">
      <div className="box_brand">
        <a href="/">
          <img src={logo} className="logo" alt="logo" />
        </a>
      </div>
      <div className="box_options">
        <ul>
          <li>
            <a href="/#top">Quiénes somos</a>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <button className="btn primary" onClick={handleOpen}>
                Servicios y contacto
                {open ? (
                  <ul className="menu" ref={menuRef}>
                    <li className="menu-item_special">
                      <a className="buttonMenu">Servicios y contacto</a>
                    </li>
                    <li className="menu-item">
                      <a href="#oaxaca" className="buttonMenu">
                        OAXACA
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#cdmx" className="buttonMenu">
                        CIUDAD DE MÉXICO
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#hidalgo" className="buttonMenu">
                        HIDALGO
                      </a>
                    </li>
                  </ul>
                ) : null}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default BurguerMenuDesktop;
