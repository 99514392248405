import React, { useState, useRef, useEffect } from "react";
import ImgPopUp from "../../assets/imgs/popUpNew.png";
import "./popUp.scss";

export default function PopUp() {
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    setMyModal(myModal === "");
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    // setTimeout(() => {
    //   setPopUp();
    // }, 10000)
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  async function setPopUp() {
    setMyModal(myModal === "");
  }

  const [myModal, setMyModal] = useState("inShow");
  return (
    <section
      className={`wrapper_modal_popup ${myModal}`}
      ref={myRef}
      onClick={handleClickInside}
    >
      <div className="modal_content">
        {/* <span className="btnInCLose text fnt_medium" onClick={setPopUp} >
              <i className="fas fa-times"></i> CERRAR
            </span> */}
        <img src={ImgPopUp} alt="Notas"/>
      </div>
    </section>
  );
}
