import "./App.scss";
import PopUp from "./components/popUp/popUp";
import { Footer } from "./components/footer/footer";
import Content from "./components/content/content";
import BurguerMenu from "./components/navbar/burguerMenu";
import BurguerMenuMobile from "./components/navbar/burguerMenuMobile";
import BurguerMenuDesktop from "./components/navbar/burguerMenuDesktop";

function App() {
  return (
    <section>
      <PopUp />
        <BurguerMenuDesktop className="BMD"/>
        <BurguerMenuMobile className="BMM"/>
      <Content />
      <Footer />
    </section>
  );
}

export default App;
